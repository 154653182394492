.dashboard-calendar{
	.fc-toolbar {
		h2{
			font-size:30px;
			color:$black;
			line-height: 2.875rem;
			text-transform: capitalize;
		}
		.fc-right{
			background:#F0F0F0;
			padding:6px;
			border-radius:2rem;
			.fc-button-group button{
				border:0;
				background:transparent;
				padding: 8px 20px;
				box-shadow: none;
				height: auto;
				border-radius:2rem;
				&.fc-state-active{
					background:$white;
				}
			}	
		}
		.fc-center{
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: fit-content;
		}
		.fc-icon-right-single-arrow:after {
			content: "\e649";
			font-weight: 700;
			font-family: 'themify';
			font-size: unset;
			top: unset;
		}
		.fc-icon-left-single-arrow:after {
			content: "\e64a";
			font-weight: 700;
			font-family: 'themify';
			font-size: unset;
			top: unset;
		}
		.fc-left{
			.fc-button-group button{
				height:40px;
				width:40px;
				border-radius:40px;
				border:0;
				line-height:40px;
				margin: 0 10px;
				text-align:center;
				background:#F6F6F6;
				color:#C1C1C1;
				&.fc-state-hover,&.fc-state-active{
					background:#DAE2FF!important;
					color:$primary;
				}
			}
			.fc-today-button{
				border:0;
				background:$primary;
				color:$white;
				border-radius:4rem;
				padding: 10px 30px;
				text-shadow:none;
				opacity: 1;
				height: auto;
			}
		}
	}
}

.date-bx{
	h2{
		font-size:58px;
		color:$white;
	}
}